
@mixin theme($colour) {
  background-color: $colour;

  hr {
    border-color: $colour;
  }

  .navigation,
  .navigation a,
  .navigation li {
    background-color: $colour;
  }

  .light h1,
  .sidebar h1,
  .sidebar h2 {
    color: $colour;

    a {
      background-color: inherit;
      color: $colour;
    }
  }
}

@mixin outline($colour) {
  // .donate-button *
  .page-header *,
  .logo * {
    &:focus {
      outline-color: $colour;
    }
  }
}

body {

  &.blau {
    @include theme($kuli-blue);
    @include outline($kuli-yellow);
  }

  &.gelb {
    @include theme($kuli-yellow);

    .warning {
      color: #fff;
      background-color: $kuli-blue;
      a {
        color: #fff;
        border-color: #fff;
      }
    }
  }

  &.gruen {
    @include theme($kuli-green);
    @include outline($kuli-yellow);
  }

  &.rot {
    @include theme($kuli-red);
    @include outline($kuli-yellow);
  }
}

.light {
  background: $content-background-colour;
  color: $base-text-colour;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $base-text-colour;
  }
}

.greentext {
  color: $kuli-green;
  font-weight: bold;
}

hr {
  border-style: solid;
  border-width: 1px 0 0;
}

blockquote {
  font-style: italic;
  margin: $line-height 0;

  &:before {
    content: '„';
    // display: inline-block;
  }

  &:after {
    content: '“';
    // display: inline-block;
  }

  p {
    display: inline;
  }

  &+.source {
    // border: 1px dotted green;
    margin-left: 8px;
    margin-top: -$line-height * .75;
    &:before {
      content: '— ';
    }
  }
}

.navigation,
.logo,
.burger-icon {
  color: $menu-link-colour;
  fill: $content-background-colour;

  a {
    color: $menu-link-colour;
  }

  svg {
    fill: $content-background-colour;
  }
}

.burger,
.logo {
  a {
    &:hover {
      border-bottom: 0;
      text-decoration: none;
    }
  }
}

small {
  @include size-1;
}

// .content,
// .sidebar-content,
// .summary,
// .permalink {
//   a {
//     &::before {
//       content: "\2192\00A0";
//     }
//   }
// }

.arrow {
  &::before {
    color: $link-colour;
    content: '\2192\00A0';
  }
}

a {
  &.img {
    border-bottom: 0 !important;
    text-decoration: none !important;

    &::before {
      content: '' !important;
    }
  }

  &:active,
  &:hover {
    border-bottom: 1px dotted $link-colour;
    text-decoration: none;
  }
}

pre {
  margin-left: 24px;
}

article {
  // @include clearfix;

  header {
    a {
      border-bottom: 0;
    }
  }
}

.article-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;

    article {
      margin-top: 20px;

      &.full {
        margin-top: 40px;
      }

      header {
        h2 {
          @include breakpoint(phone) {
            @include size1;
          }

          .date {
            @include size-1;
            font-weight: normal;
          }

        }
      }
    }
  }
}

.hashes {

  &:before {
    color: $link-colour;
    content: '### ';
  }
}

.content {
  p {
    &:last-child {
      margin-bottom: $line-height / 2;
    }
  }
}

.pagination {
  margin: 0;
  padding: 20px 0;
  text-align: center;
}

.partner-list {
  margin: 0;
  padding: 0;

  &+ul {
    @include clearfix;
    align-content: stretch;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 40px 0 0;
    padding: 0;

    li {
      flex-grow: 1;
      margin: 0;
      min-height: 95px;
      min-width: 140px;
      padding: 0 0 20px;
      position: relative;
      text-align: center;
      vertical-align: middle;

      img {
        width: 140px;
      }
    }
  }
}

.partner-logo {
  min-width: 30px;  // for spinner
  position: relative;
}

.masthead {
  line-height: 0;

  img {
    max-width: 100%;
    width: 100%;
  }
}

.link-list {
  // border: 1px dashed orange;
  display: table;
  list-style: none;
  margin: 0;
  padding: 0;
  // @include size-1;

  li {
    // border: 1px dotted green;
    margin: 0;
    padding: 5px 0 0;
    // display: table-row;

    img,
    svg,
    span {
      display: table-cell;
      vertical-align: middle;
    }

    img,
    svg {
      // border: 1px dashed blue;
      fill: $kuli-red;
      height: 16px;
      width: 16px;
    }

    span {
      padding-left: 5px;
    }

    a {
      // border: 1px solid red;
      color: $base-text-colour;
      display: table-row;
      font-weight: normal;
      padding-left: 8px;

      &:hover,
      &:active,
      &:visited {
        border-bottom: 0;
        text-decoration: none;
      }
      // padding-left: 25px;
    }
  }
}

.mobile-header {
  // border: 1px dashed green;
  @include clearfix;

  margin-top: 20px;

  .logo {
    width: 90px;
  }

  .burger {
    float: right;
    height: 48px;
    // margin: 0;
    width: 48px;

    a {
      display: block;
      height: 100%;
      text-align: right;
      width: 100%;

      svg {
        height: 30px;
        width: 100%;
        fill: $menu-link-colour;
      }
    }
  }
}

.news,
.newsletter {

  .date {
    font-weight: bold;
  }

  .thumb {
    float: left;
    margin: 3px 10px 0 0;
  }
  // causes element to expand to fit its floated children
  overflow: auto;
}

.headshot {
  // border: 1px dotted green;
  // display: inline-block;
  float: left;

  margin: 3px 10px 0 0;

  min-height: 100px;
  min-width: 100px;
  max-width: 165px;

  a:hover {
    border-bottom: 0;
  }

  img {
    border: 1px solid $border-colour;
    // max-width: 165px;
    max-width: 100%;
    padding: 0;
  }
}

.press-articles {
  tbody {
    tr>td {
      vertical-align: middle;

      &.thumbnail {

        a:hover {
          border: 0;
          text-decoration: none;
        }
      }
    }
  }
}

// .press-list is used in Markdown to mark the *following* list
// as a list of articles.
.press-list+ul,
.press {
  @include clearfix;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  // margin: $line-height 0 0;
  margin: 0;
  padding: 0;

  li {
    @include breakpoint(phone) {
      max-width: none;
      width: 100%;
    }
    display: inline-block;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin: $line-height 8px 0;
    max-width: 150px;
    padding: 0;
    position: relative;
    width: 150px;
  }
}

.footnotes {
  @include size-1;
}

.press-article {
  // border: 1px dotted grey;

  // max-width: 150px;
  // width: 150px;

  text-align: center;
}


.thumb {

  a {

    &:hover,
    &:link {
      border: 0;
      text-decoration: none;
    }
  }

  img {
    border: 1px solid $border-colour;
    // max-height: px;
    max-width: 100px;
    width: 100px;
  }
}

// Immediately precedes a table of date->text rows
.date-table+table,
table.dates {
  margin: $line-height 0 $line-height / 2;

  tr {

    td,
    th {
      padding: 4px 8px;
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    td {
      &:first-child {
        min-width: 5em;
      }
    }
  }

  @include mobile-only {
    tr {
      display: block;

      &+tr {
        margin-top: $line-height / 2;
      }

      th,
      td {
        display: inline;
        padding: 0;

        &:first-child {
          &:after {
            content: ' —';
          }
        }
      }
    }
  }
}

.swirl {
  // https://alistapart.com/article/creating-intrinsic-ratios-for-video/
  @include ie {
    height: 0;
    padding-bottom: 54%; // aspect ratio of swirls
    position: relative;
  }
  margin: 0 0 12px;
  padding: 0;
  text-align: center;

  img,
  svg {
    // https://alistapart.com/article/creating-intrinsic-ratios-for-video/
    @include ie {
      left: 0;
      position: absolute;
      top: 0;
    }

    display: block;

    fill: $content-background-colour;

    // height: 307px;
    // max-width: 100%;
    height: 100%;
    margin: auto;
    width: 100%;
  }

  @include breakpoint(phone) {
    // move up swirl on mobile, otherwise it's too far (-looking)
    // from the logo
    margin-top: -10px;
  }
}

.related {
  margin-top: $line-height;

  ul {
    list-style: none;

    margin-left: 0;

    .date {
      @include size-2;
      color: $base-text-colour;
      padding-left: 8px;
      // font-weight: normal;
    }
  }
}

// Partner pages at /partner/
.terms-list {
  .count {
    @include size-2;
  }
}

// List of partners at /sozialpartner/ & /kulturpartner/
.partners {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    // min-height: 110px;

    &+li {
      margin-top: 24px;
    }
  }

  .partner {
    display: table-row;
    min-height: 140px;

    &>* {
      display: table-cell;
      vertical-align: middle;
    }

    // .link {
    //   margin-top: 5px;
    // }

    h2 {
      @include size1;

      a {
        color: inherit;

        &:hover {
          border-bottom: 0;
        }
      }
    }

    .logo {
      // border: 1px dotted orange;
      @include mobile-only {
        margin-right: 8px;
        width: 110px;
      }
      float: left;

      // height: 90px;
      // width: 140px;

      margin-left: 0;
      margin-right: 32px;
    }
  }
}

.partner {
  // causes element to expand to fit its floated children
  overflow: auto;

  .logo {
    float: right;
    height: 90px;
    margin-left: 32px;
    // max-width: 140px;
    width: 140px;
  }
}

.ambassador {
  h2 {
    a {
      color: inherit;

      &:hover,
      &:active {
        border-bottom: 0;
      }
    }
  }

  .content {

    p {
      &:first-child,
      &:last-child {
        margin: $line-height / 2 0;
      }
    }
  }

  .headshot {
    margin-top: $line-height / 2 + 3px;
  }
}

.inline-image {
  float: left;
  margin: 3px 10px 0 0;

  &.right {
    float: right;
    margin: 3px 0 0 10px;
  }

  img {
    width: 150px;
  }
}

.row {
  display: table-row;

  &>* {
    @include mobile-only {
      display: block;

      &.headshot {
        float: left;
      }
    }
    display: table-cell;
  }
}

.vmid {
  &>* {
    vertical-align: middle;
  }
}

.vtop {
  &>* {
    vertical-align: top;
  }
}

// Used for donation buttons
.big-button,
.medium-button {
  @include size5;
  text-align: center;

  @include breakpoint(phone) {
    @include size3;
  }

  a {
    background: $kuli-green;
    border: 0;
    // border: 4px solid $kuli-green;
    color: $content-background-colour;
    display: inline-block;
    &:hover {
      background: $kuli-red;
      text-decoration: none;
    }
    padding: 1em 3em;
    // max-width: 14em;

    @include breakpoint(phone) {
      padding: 1em;
    }
  }
}

.medium-button {
  @include size2;
}

table.donate {
  margin-left: 16px;

  th {
    font-weight: bold;
    text-align: left;
  }

  @include breakpoint(phone) {
    margin-left: 0;
  }
}

.warning {
  background-color: $kuli-yellow;
  font-weight: bold;
  padding: 20px;
  a {
    color: #000;
    border-color: #000;
    border-bottom: 1px dotted #000;
  }
}

.clearfix {
  @include clearfix;
}
