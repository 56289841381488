
#analytics-manager {
  font-weight: bold;
  text-align: center;
}

body {

  .cookie-consent {
    background: $kuli-green;
  }

  &.gruen,
  &.blau {
    .cookie-consent {
      background: $kuli-red;
    }
  }
}

.cookie-consent {
  bottom: 0;
  color: $attention-text-colour;
  left: 0;
  margin: 0;
  padding: 8px 4px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 1000;

  .consent-text {
    @include size-1;
  }

  .cookie-wrapper {
    padding-right: 60px;
  }

  .cookie-buttons {
    margin: 5px 0 0;
    padding: 0;

    li {
      display: inline-block;
      list-style: none;
    }
  }

  .dismiss-icon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAF3RSTlMAbAriBdQThVOwoRxfe/jvuzbHmigwQ6URMCkAAAENSURBVCjPXVJbgoAgCERNzVdlL+5/0wXR1l1+0oYZBhCgx1PCaY+YHfyN1eKImKb/7sApNvUBt6G7LdXptF4bna/BIGDPIy1FgrycScrck7YiaG3FiXGD7qSFinhKXehMrjLoQxSWwEVOREp4qDhAFnECECu8iCdAQSzQFNA3gEuQJweBkzpkR23y97KmDMS3NhvAQpkNaHHFjPAZV9xN6q7w69EzOYpCA8KADi6e25i6Ky+Q3nBfwNFwE9y7UAmymg1EcUifx4grbx2kXTpJW5uF7vPUoO3wqH7H3tZAgOl7vQg6304pJLXVkdbaN7Eof/BKTZ0eiJneQZifCC3slN97rPA/3JvVWpfv/gN22ha3zx1llwAAAABJRU5ErkJggg==');
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: 20px;
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
  }

  a {
    &:hover,
    &:link,
    &:visited {
      border: 0;
      color: $attention-text-colour;
      text-decoration: none;
    }
  }

  .info-link {
    display: block;
    float: right;
    font-size: 80%;
    margin-top: 5px;
  }
}
