
$logo-width: 176px;
$sidebar-width: 205px;
$sidebar-width-narrow: 180px;


$bp-desktop: 940px;
$bp-tablet: 939px;
$bp-phablet: 649px;
$bp-phone: 559px;

// Only apply to IE 10+
// https://stackoverflow.com/a/47240261/18763
@mixin ie {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}

@mixin clearfix {
  &:after {
    clear:  both;
    content: "";
    display: block;
  }
}

@mixin hashover {
	@media (hover:hover) { @content; }
}

@import "reset";
// @import "normalize";
@import "colours";
@import "breakpoints";
@import "typography";
@import "layout";
@import "theme";
