
body {
  margin: 0;
  padding: 0;
}

// .page contains the entire contents of the page.
// Limits max width and centres content.
.page {
  padding: 0 20px;
  // position: relative;
  margin: auto;
  max-width: 996px;

  @include breakpoint(phone) {
    padding: 0 12px;
  }
}

.floaters {
  // border: 1px dotted green;

  @include breakpoint(tablet) {
    @include clearfix;
    float: right;
    max-width: $sidebar-width + 24;
  }

  @include breakpoint(phablet) {
    max-width: $sidebar-width-narrow + 24;
  }
}

.sidebar {
  float: right;
  width: $sidebar-width;

  @include breakpoint(tablet) {
    margin-top: $line-height;
  }

  @include breakpoint(phablet) {
    width: $sidebar-width-narrow;
  }
}

.logo {
  float: left;
  width: $logo-width;

  img,
  svg {
    height: auto;
    width: 100%;
  }

  @include breakpoint(tablet) {
    @include clearfix;
    float: right;
    width: $sidebar-width;
  }

  @include breakpoint(phablet) {
    max-width: $sidebar-width-narrow;
  }

  @include breakpoint(phone) {
    float: left;

    img,
    svg {
      height: 65px;
      // width: 90px;
      max-width: 100%;
    }
  }
}

.page-header {
  @include clearfix;

  @include breakpoint(phone) {
    margin-bottom: 20px;
  }
}

.page-content,
.page-footer {
  margin-left: $logo-width + 24;
  margin-right: $sidebar-width + 24;

  @include breakpoint(tablet) {
    margin-left: 0;
  }

  @include breakpoint(phablet) {
    margin-right: $sidebar-width-narrow + 24;
  }

  @include breakpoint(phone) {
    margin-right: 0;
  }
}

.page-footer {
  @include clearfix;
  @include size-1;
  margin-bottom: $line-height * 2;
  margin-top: -1px;  // close 0.5px gap to .page-content on mobile
  text-align: center;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.donate-button {
  text-align: center;
  margin-top: 60px;

  img,
  svg {
    // box-sizing: border-box;
    display: block;
    height: 180px;
    z-index: 2;
  }

  @include breakpoint(tablet) {
    margin-top: 20px;

    img {
      height: 160px;
    }
  }

  @include breakpoint(phone) {
    margin-bottom: $line-height * 2;

    img {
      height: 200px;
    }
  }
}

article {
  img {
    max-width: 100%;
  }
}

.container {
  padding: 20px;
}
