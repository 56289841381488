
// ************************************************************************
// Fonts and basic sizes
// ************************************************************************

$header-font-family: 'Trebuchet MS', sans-serif;
$base-font-family: 'Trebuchet MS', sans-serif;

$base-font-size: 15px;
$line-height: $base-font-size * 1.5;
$alt-font-size: 18px;
$alt-line-height: $line-height * 1.3333; /* 3 alt lines to 4 normal */

$mobile-font-size: 20px;
$mobile-line-height: $mobile-font-size * 1.5;


html {
  word-break: break-word;
}

.nobreak {
  word-break: normal;
}

// ************************************************************************
// General mixins
// ************************************************************************


// ************************************************************************
// Fonts, text sizes and line spacing
// ************************************************************************

body {
  font-family: $base-font-family;
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: bold;
  // font-weight: 600;
  overflow: auto;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.footnote-return {
    &::before {
      content: '' !important;
    }
  }
}

@mixin resize-by($multiplier, $font-size: $base-font-size, $lheight: $line-height) {
  $size: ($font-size * $multiplier);
  font-size: $size;
  $height: $lheight;
  @if ($size > ($lheight * .85)) {
    // $height: $alt-line-height;
    $height: $lheight * 1.33;
  }
  @if ($size > ($height * .85)) {
    $height: $lheight * 1.5;
  }
  @if ($size > ($height * .85)) {
    $height: $lheight * 2;
  }
  $rem: $height - $size;
  line-height: $height;

  // margin-top: $rem * 0.6666;
  // margin-bottom: $rem * 0.3333;
  // margin-top: $rem;
}

@mixin size8 {
  @include resize-by(2);
  @include breakpoint(phone) {
    @include resize-by(2, $mobile-font-size);
  }
}

@mixin size7 {
  @include resize-by(1.875);
  @include breakpoint(phone) {
    @include resize-by(1.875, $mobile-font-size);
  }
}

@mixin size6 {
  @include resize-by(1.75);
  @include breakpoint(phone) {
    @include resize-by(1.75, $mobile-font-size);
  }
}

@mixin size5 {
  @include resize-by(1.625);
  @include breakpoint(phone) {
    @include resize-by(1.625, $mobile-font-size);
  }
}

@mixin size4 {
  @include resize-by(1.5);
  @include breakpoint(phone) {
    @include resize-by(1.5, $mobile-font-size);
  }
}

@mixin size3 {
  @include resize-by(1.375);
  @include breakpoint(phone) {
    @include resize-by(1.375, $mobile-font-size);
  }
}

@mixin size2 {
  @include resize-by(1.25);
  @include breakpoint(phone) {
    @include resize-by(1.25, $mobile-font-size);
  }
}

@mixin size1 {
  @include resize-by(1.125);
  @include breakpoint(phone) {
    @include resize-by(1.125, $mobile-font-size);
  }
}

@mixin size0 {
  font-size: $base-font-size;
  @include breakpoint(phone) {
    font-size: $mobile-font-size;
  }
}

@mixin size-1 {
  @include resize-by(.875);
  @include breakpoint(phone) {
    @include resize-by(.875, $mobile-font-size);
  }
}

@mixin size-2 {
  @include resize-by(.75);
  @include breakpoint(phone) {
    @include resize-by(.75, $mobile-font-size);
  }
}

@mixin size-3 {
  @include resize-by(.625);
  @include breakpoint(phone) {
    @include resize-by(.625, $mobile-font-size);
  }
}

h1 {
  @include size6;
  margin-bottom: $line-height;
  text-decoration: underline;
}

h2 {
  @include size3;
}

h3 {
  @include size2;
}

h4 {
  @include size1;
}

h5 {
  @include size0;
}

h6 {
  @include size-1;
}

.small {
  @include size-1;
}

.smaller {
  @include size-2;
}

html>body {
  font-size: $base-font-size;
  line-height: $line-height;

  @include breakpoint(phone) {
    font-size: $mobile-font-size;
    line-height: $mobile-line-height;
  }
}

ul,
ol,
dl,
address,
dl,
p {
  line-height: $line-height;
  margin: $line-height 0;

  &:first-child {
    margin-top: $line-height/2;
  }

  @include breakpoint(phone) {
    line-height: $mobile-line-height;
    margin: $mobile-line-height 0;
  }
  // &:first-child {
  //   margin-top: 0;
  // }
}

ul,
ol {
  margin-left: 16px;

  li {
    margin-left: 24px;
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

dl {

  dt {
    font-weight: bold;

    &:after {
      content: ':';
    }
  }

  dd {
    margin: 0 0 $line-height * .5 0;
  }
}

sup {
  font-size: smaller;
  vertical-align: super;

  &.footnote-ref {
    @include size-2;
    line-height: 0;
    vertical-align: super;

    &::before {
      content: '[';
    }

    &::after {
      content: ']';
    }

    &>a {
      &::before {
        content: '' !important;
      }
    }
  }
}

td,
th {
  padding: 0 4px;
}

table {
  margin: $line-height / 2 0;
}

// ************************************************************************
// Footnotes
// ************************************************************************

div {

  &.footnote {
    @include size-1;
  }
}

// ************************************************************************
// Non-standard elements
// ************************************************************************

#mti_wfs_colophon {
  img {
    display: none;
  }
}
