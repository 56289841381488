
.sidebar {
  transition: width .2s ease-out 0s;
}

.sidebar h2 {
  @include size1;
  text-decoration: underline;
}

.sidebar-content {
  .catlink {
    margin-top: 20px;
    text-align: center;
  }

  &+.sidebar-content {
    margin-top: 20px;
  }
}

.sidebar .news,
.sidebar .newsletter-form {
  @include size-2;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }

  article {
    margin-top: 12px;

  	a {
  		&:before {
		    content: "\2192\00A0";
    	}
  	}

  	.date {
  		font-weight: bolder;

  		a,
  		a:link,
  		a:hover {
  			color: inherit;
  			text-decoration: none;
  		}
  	}

  	.summary {
      display: inline;

  		p {
  			display: inline;
  		}
  	}
  }
}

.sidebar .newsletter-form {
  form {
    text-align: center;
  }

  input {
    @include size-2;
    box-sizing: border-box;
    width: 100%;
  }

  p {
    margin: 12px 0;
  }
}


.invisible {
  opacity: 0;
  transition: opacity .5s;
}

.ambassador {

  overflow: hidden;
  transition: all .25s linear;

  * {
    transition: opacity .5s;
  }

  .pic {
    margin: 12px 0 0;
    text-align: center;

    &.spinner {
      background-position: center;
    }

    img {
      height: auto;
      max-width: 100%;
      // required by IE 11 (otherwise image size is not constrained)
      width: 100%;
    }
  }

  .info {
    @include size-1;
  }

  .meta {
    @include size-2;
  }

  .info {
    margin: 12px 0 0;
  }

  .controls {
    margin: 16px 0 4px;
    // min-height: 10px;
    position: relative;
    bottom: 0;
    text-align: center;

    a:hover {
      border-bottom: 0;
    }

    img {
      max-height: 10px;
      max-width: 10px;
    }

    ul {
      line-height: 10px;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 4px;
        padding: 0;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}