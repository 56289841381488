
ul.social {
  display: block;
  list-style: none;
  margin: 0;
  min-width: 70px;
  text-align: right;
  padding: 0;
  // width: $sidebar-width + 24;

  li {
    // flex: 1;
    display: inline-block;
    margin: 0;
    padding: 0;
    // min-height: 18px;
    // min-width: 18px;
    // width: auto;

    a {
      // background-color: $social-colour;
      // border: 1px dashed green;
      border: 0;
      display: block;
      // fill makes no difference to SVGs served via <img>
      // fill: $menu-link-colour;
      margin: 0;
      padding: 1px;
      text-align: center;
      height: 18px;
      width: 18px;

      &:hover { border: 0; }

      &.twitter:hover    { background-color: $twitter-colour; }
      &.facebook:hover   { background-color: $facebook-colour; }
      &.rss:hover        { background-color: $rss-colour; }
      &.instagram:hover  {
        background-color: $instagram-colour;
        // @include instagram-gradient;
      }

      img,
      svg {
        max-height: 14px;
        max-width: 14px;
        margin: 0;
        // min-width: 14px;
      }
    }
  }

  @include breakpoint(phone) {
    margin: 0 0 40px;
    text-align: center;

    li {

      &+li {
        margin-left: 30px;
      }

      a {
        height: 38px;
        width: 38px;
        // padding: 2px;

        &.twitter   { background-color: $twitter-colour; }
        &.instagram { background-color: $instagram-colour; }
        &.facebook  { background-color: $facebook-colour; }
        &.rss       { background-color: $rss-colour; }

        img,
        svg {
          // border: 1px dashed orange;
          height: 30px;
          width: 30px;
          // needed to override defaults
          max-height: 30px;
          max-width: 30px;
          margin: 4px 0;
        }
      }
    }
  }
}
