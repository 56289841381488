
.carousel {

  // overflow: hidden;
  margin-bottom: 24px;

  &>* {
    width: 100%;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .content {
    // height of Bürgermeister box
    // height: 340px;
    list-style: none;
    overflow: hidden;
    position: relative;
    transition: height .3s ease-in-out;

    li {
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity .8s linear;
      z-index: -1;

      &.current {
        opacity: 1;
        top: 0;
        z-index: 1;
      }
    }
  }

  &.active {
    .controls {
      display: block;
      opacity: 1;
    }
  }

  a,
  a:hover,
  a:link {
    border-bottom: 0;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }

  .pic {
    margin: 12px 0 0;
    text-align: center;
    width: 100%;

    img {
      height: auto;
      max-width: 100%;
      // Required by IE 11 (otherwise image size is not constrained)
      width: 100%;
    }
  }

  .info {
    @include size-1;
    margin: 12px 0 0;
  }

  .meta {
    @include size-2;
  }

  .controls {
    bottom: 0;
    display: none;
    margin: 16px 0 4px;
    opacity: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    white-space: nowrap;

    img {
      max-height: 10px;
      max-width: 10px;
    }

    svg {
      // border: 1px dashed darkcyan;
      fill: none;
      // max-height: 10px;
      // max-width: 10px;
      stroke: $base-text-colour;
    }

    .next,
    .previous {
      svg {
        fill: $base-text-colour;
        stroke: none;
      }
    }

    a:hover,
    .current {
      svg {
        fill: $base-text-colour;
      }
    }

    ul {
      line-height: 10px;

      li {
        display: inline-block;
        margin: 0 3px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        // There are *just* too many ambassadors for the
        // phablet-sized screen
        @include breakpoint(phablet) {
          &.previous,
          &.next {
            display: none;
          }
        }
      }
    }
  }

  &.carousel-sidebar {
    .content {
      // height of Bürgermeister box
      height: 340px;
    }
  }

  &.carousel-page {
    .content {
      // height of Bürgermeister box
      height: 760px;
    }
    .controls {
      margin-top: 0;
    }
  }
}
