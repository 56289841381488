
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: $bp-desktop) { @content; }
	}
	@else if $point == tablet {
		@media (max-width: $bp-tablet) { @content; }
	}
	@else if $point == phablet {
		@media (max-width: $bp-phablet) { @content; }
	}
	@else if $point == phone {
		@media (max-width: $bp-phone) { @content; }
	}
}

@mixin mobile-only {
	@include breakpoint(phone) { @content; };
}

@mixin no-mobile {
	@media (min-width: $bp-phone + 1) { @content; }
}

.no-mobile {
	@include mobile-only {
		display: none;
	}
}

.mobile-only {
	@media (min-width: $bp-phone) {
		display: none !important;
	}
}

// Debugging
#media-query {
	@include breakpoint(desktop) {
		&::before {
			content: 'desktop (>=940px)';
		}
	}
	@include breakpoint(tablet) {
		&::before {
			content: 'tablet (<940px)';
		}
	}
	@include breakpoint(phablet) {
		&::before {
			content: 'phablet (<650px)';
		}
	}
	@include breakpoint(phone) {
		&::before {
			content: 'phone (<560px)';
		}
	}
}