
//            oo          oo
//
// 88d8b.d8b. dP dP.  .dP dP 88d888b. .d8888b.
// 88'`88'`88 88  `8bd8'  88 88'  `88 Y8ooooo.
// 88  88  88 88  .d88b.  88 88    88       88
// dP  dP  dP dP dP'  `dP dP dP    dP `88888P'

@mixin simple-transition {
  transition:
    background-color .2s ease-in-out 0s,  // social icons
    border .2s ease-in-out 0s,       // underlines on menu items
    opacity .2s linear .05s,
    z-index 0s linear 0s;
}

@mixin nav-transition {
  transition:
    // all .2s ease-in-out .1s,
    background-color .2s ease-in-out 0s,  // social icons
    border .1s ease-in-out 0s,       // underlines on menu items
    opacity .2s linear .05s,
    visibility .2s linear .05s,
    z-index 0s linear .05s;

  // Separate transition for mobile menu
  @include breakpoint(phone) {
    transition:
      max-height .25s ease-in,
      opacity .5s ease-out 0s,
      z-index 0s linear 0s;
  }
}

@mixin nav-invisible {
  @include nav-transition;
  @include breakpoint(phone) {
    margin-top: 0;
    max-height: 0;
    transform: none;
    z-index: 1;

    li {
      &:first-child {
        padding-top: 20px;
      }
    }
  }
  opacity: 0;
  // visibility: hidden;
  display: none;
  z-index: 0;
}

@mixin nav-visible {
  @include nav-transition;
  @include breakpoint(phone) {
    // margin-top: 20px;
    max-height: 900px;
    transform: none;
    z-index: 1;
  }
  opacity: 1;
  display: block;
  // visibility: visible;
  z-index: 2;
}

// 88d8b.d8b. .d8888b. 88d888b. dP    dP
// 88'`88'`88 88ooood8 88'  `88 88    88
// 88  88  88 88.  ... 88    88 88.  .88
// dP  dP  dP `88888P' dP    dP `88888P'

#menu {
  // Toggle entire navigation on phone
  @include breakpoint(phone) {
    .navigation {
      @include nav-invisible;
    }

    &.open,
    .open {
      &>.navigation {
        @include nav-visible;
      }
    }
  }
}

.navigation {
  @include nav-transition;
  @include breakpoint(tablet) {
    margin-left: 0;
  }
  // border: 1px dashed yellow;
  display: flex;
  list-style: none;
  // Assume top-level element, sibling of sidebars
  margin: 20px 0 0 $logo-width + 24;
  position: relative;

  * {
    @include nav-transition;
  }
  // Don't let long, German menus get squashed
  word-break: normal;
  z-index: 2;

  li {
    // border: 1px dotted green;
    display: inline-block;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;
    position: relative;
    text-align: left;

    // Shrink space between menu items on smaller screens.
    // Default is 24px (default margin for <li>).
    @include breakpoint(tablet) {
      margin-left: 16px;
    }

    @include breakpoint(phablet) {
      margin-left: 10px;
    }

    // Let social item expand to keep it separate.
    &:last-child {
      flex-grow: 1;
    }

    &.active {
      &>a {
        border-bottom: 2px solid $menu-link-colour;
      }
    }

//                   dP
//                   88
// .d8888b. dP    dP 88d888b. 88d8b.d8b. .d8888b. 88d888b. dP    dP
// Y8ooooo. 88    88 88'  `88 88'`88'`88 88ooood8 88'  `88 88    88
//       88 88.  .88 88.  .88 88  88  88 88.  ... 88    88 88.  .88
// `88888P' `88888P' 88Y8888' dP  dP  dP `88888P' dP    dP `88888P'

    &:hover {
      .navigation {
        @include nav-visible;
      }
    }

    &.open {
      .navigation {
        @include nav-visible;
        @include breakpoint(phone) {
          border: 0;
          margin: 0;
          padding: 0 0 0 24px;
          position: relative;
        };
      }
    }

    .navigation {
      border: 1px solid $dropdown-border-colour;
      border-top: 0;
      display: block;
      // box-shadow: 1px 1px 3px $header-colour3;

      @include nav-invisible;

      left: 0;
      margin: 0;
      padding: 8px 16px;
      position: absolute;
      top: 100%;


      li {
        margin-bottom: 0;
        margin-left: 0;
        text-align: left;

        &+li {
          margin-top: 4px;
        }
      }
    }
  }

  a {
    border-bottom: 2px solid transparent;
    display: inline-block;
    font-size: 1.125 * $base-font-size;
    font-weight: normal;

    &:hover {
      border-bottom: 2px solid $menu-link-colour;
      text-decoration: none;
    }

    &:active {
      display: inline-block;
    }
  }

  @include breakpoint(phone) {
    // border: 1px dashed orange;

    display: block;
    overflow: hidden;

    li {
      display: block;
      margin: 0;
      // padding-top: 20px;

      &+li {
        padding-top: 20px;
      }

      a {
        @include size3;
        font-weight: 600;
      }
    }
  }

  // IE doesn't support hover:hover.
  @include ie {
    li {
      &:hover {
        .navigation {
          display: block;
        }
      }

      .navigation {
        display: none;
        margin-top: 3px;
        visibility: visible;
        opacity: 1;
        z-index: 1;
      }
    }
  }
}

//          dP       oo
//          88
// .d8888b. 88  .dP  dP 88d888b.
// Y8ooooo. 88888"   88 88'  `88
//       88 88  `8b. 88 88.  .88
// `88888P' dP   `YP dP 88Y888P'
//                      88
//                      dP

.skip {
  @include size-2;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 10px;
  z-index: -1;

  a {
    color: transparent;
    font-weight: normal;

    &:focus {
      color: $menu-link-colour;
      z-index: 1000;
    }

    &:hover {
      color: $menu-link-colour;
      border: none;
      text-decoration: none;
    }
  }

  @include breakpoint(phone) {
    @include size0;

    text-align: right;
    top: 22px;
    right: 100px;
  }
}
