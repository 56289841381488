div.downloads {
  margin-top: 32px;
  h2 {
    @include size2;
  }
}

ul.downloads {
  font-weight: bold;
  list-style: none;

  li {
    display: table-row;

    .download-icon {
      // border: 1px dotted green;
      height: 32px;
      width: 32px;

      img {
        max-height: 32px;
      }
    }

    a:hover {
      border-bottom: 0;
    }

    &>* {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
