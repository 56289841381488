
.noprint,
.debug {
	display: none !important;
}

html>body {
	@include size-1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $base-text-colour !important;
}

h1 {
  @include size4;
}

h2 {
  @include size2;
}

h3 {
  @include size1;
}

h4 {
  @include size0;
}

h5 {
  @include size-1;
}

h6 {
  @include size-1;
}

.page-footer,
.page-content {
	// border: 5px solid black;
	margin: 0;
	padding: 0;
	width: 100%;
}

.partners {

	li {
		float: left;
		margin: 0;
		padding: 0 16px 0 0;
		width: 45%;

		&+li {
			margin-top: 0;
		}

		.partner {
			// min-height: 0;
			margin-top: 8px;
		}
	}

	.partner {
		h2 {
			@include size-1;
			margin-top: 4px;
		}
	}

	@include clearfix;
}