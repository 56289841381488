
$burger-segment: 4px;
$burger-width: $burger-segment * 4;
$burger-height: $burger-segment * 5;

.burger {
  // border: 1px dotted green;
  display: inline-block;
  height: $burger-height;
  margin: 0 8px;
  padding: 0;
  width: $burger-width;

  span {
    // border: 1px dashed orange;
    background-color: $base-text-colour;
    display: block;
    height: $burger-segment;
    margin: 0;
    padding: 0;
    animation: pulse .7s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-out;

    &:nth-child(2) {
      animation-delay: .2s;
    }

    &:nth-child(3) {
      animation-delay: .4s;
    }

    &+span {
      margin-top: $burger-segment;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: .1;
  }
  100% {
    opacity: .9;
  }
}

.loading-message {
  color: #999;
  margin: 20px 0;
  text-align: center;

  .spinner {
    min-height: 15px;
    min-width: 20px;
    margin-bottom: -2px;
  }
}

.spinner {
  // background: transparent url('../img/spinner.svg') center no-repeat;
  // background-size: 18px 18px;
  background: transparent url('../img/aniburger.svg') center no-repeat;
  background-size: 12px 15px;
  display: inline-block;
  // min-height: 26px;
  // min-width: 20px;

  // Internet Explorer doesn't animate SVGs used as background images
  @include ie {
    background: transparent url('../img/spinner.gif') center no-repeat;
    background-size: auto;
    display: inline-block;
    min-height: 24px;
    min-width: 24px;
  }
}

.spinner-centre {
  background-position: center;
}

.aniburger {
  fill: rgb(140, 140, 140);
  .top {}
  .middle {}
  .bottom {}
}