input {
  border-color: $kuli-blue;
  border-style: solid;
  box-sizing: border-box;
  border-width: 1px;
  font-family: $base-font-family;
  // need explicit height for IE11
  min-height: $line-height * 1.133;
  line-height: $line-height;

  padding: 1px 4px;
  margin: 4px 0;
  width: 100%;


  &[type="submit"],
  &[type="button"] {
    -webkit-appearance: none;
    width: auto;
  }

  @include breakpoint(phone) {
    @include size0;
    margin: 8px 0;
    padding: 8px;
    width: 100%;
  }

  &.primary {
    background-color: $kuli-blue;
    color: $content-background-colour;
  }

  &:disabled {
    background-color: $disabled-light;
    border-color: $disabled-dark;
    color: $disabled-medium;
  }
}

// decoy field for bots
.bescheisser {
  position: absolute;
  left: -10000px;
}

// Only visible to screenreaders. Taken from bootstrap.
// https://github.com/twbs/bootstrap/blob/f6694b74405261ed454d409ea5251f08cdf6c51c/scss/mixins/_screen-reader.scss#L8
.screenreader {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

body.gruen {
  input {
    border-color: $kuli-green;

    &.primary {
      background-color: $kuli-green;
    }
  }
}
